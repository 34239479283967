import { RouteLocationRaw, Router } from 'vue-router'
import { useAuth } from './useAuth'
import useGeneralPermissions from '@/composables/useRolesAndPermissions'

export function configureNavigationGuards(router: Router) {
  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const auth = useAuth()
    await auth.waitUntilLoaded()

    if (to.meta.public || auth.isAuthenticated) {
      return true
    }

    // If the user is returning to the app after a failed authentication and the redirection is to a protected page,
    // Then we catch the error in here to avoid infinite redirections
    if (window.location.search.includes('error=') || window.location.hash.includes('error=')) {
      const params = new URLSearchParams(
        window.location.search.includes('error=') ? window.location.search : window.location.hash
      )

      return {
        name: 'error',
        params: {
          statusCode: params.get('error') || 500,
          message: params.get('error_description') || 'Authentication Error',
        },
      }
    }

    if (auth.error) {
      return {
        name: 'error',
        params: {
          statusCode: 500,
          message: auth.error || 'Authentication Error',
        },
      }
    }

    auth.loginWithRedirect(to.fullPath)
    // Cancels navigation, a redirection will happen anyway
    return false
  })

  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const { userRolesWithPermissions, permissionDeniedRoute } = useGeneralPermissions()
    const auth = useAuth()
    await auth.waitUntilLoaded()

    if (
      userRolesWithPermissions.value &&
      to.name &&
      to.name.toString() &&
      userRolesWithPermissions.value.restrictedPages.includes(to.name.toString())
    )
      return permissionDeniedRoute
    else {
      return true
    }
  })
}
